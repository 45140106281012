import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import Header from "../../Components/Header/Header";
import Sidebar from "../../Components/SideBar/Sidebar";
import PageTitle from "../../Components/PageTitle/PageTitle";
import ProjectTableHeader from "../../Components/TableHeader/ProjectTableHeader";
import ProjectTable from "../../Components/ProjectTable/ProjectTable";
import ProjectManagementCard from "../../Components/ProjectManagementCard/ProjectManagementCard";
import axios from 'axios';
import config from '../../config/config';
import styles from "./ConsolePage.module.css";
const ConsolePage = () => {
    const cur_page = '/console';
    const title = 'Project Management';
    const subtitle = 'Here you can manage all your projects.';
    const buttontxt = 'Create Project';
    const [Active_projects, setActiveProjects] = useState([]);
    const [Paused_projects, setPausedProjects] = useState([]);
    const [Completed_projects, setCompletedProjects] = useState([]);
    const [data, setData] = useState([]);

    const [status, setStatus] = useState({
      text: 'Active',
      color: '#34a853',
  });
  const [if_search, setIfSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchStatus, setSearchStatus] = useState('Project Name');
  const companyName = useSelector((state) => state.auth.companyName);
  
    const fetchProjects = async () => {
      try {
        const response = await axios.post(`${config.BACKEND_ENDPOINT}/project/clientSearch`,{
          companyName:companyName
        });
        setActiveProjects(response.data.active_projects);
        setPausedProjects(response.data.paused_projects);
        setCompletedProjects(response.data.completed_projects);
        if(if_search){
          let searchType ="project_id";
          
          if(searchStatus === 'Project ID'){
            searchType ="project_id";
          }else if(searchStatus === 'Project Name'){
            searchType ="project_name";
          }else if(searchStatus === 'Company Name'){
            searchType ="company_name";
          }else{
            searchType ="creation_date";
          }
          const param = {
            searchType: searchType,
            searchTerm: searchText,
            companyName: companyName
          };
          const response = await axios.post(`${config.BACKEND_ENDPOINT}/project/clientSearchFilter`, param);
          setData(response.data.projects);
        }else{
          if(status.text === 'Active'){
            setData(response.data.active_projects);
          }else if(status.text === 'Paused'){
            setData(response.data.paused_projects);
          }else{
            setData(response.data.completed_projects);
          }
        }
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    };

    useEffect(() => {
        fetchProjects();
      }, []);
    
    const handleCreateProject = () => {
        window.location.href = '/createproject';
    };
    return (
        <div className={styles.overall}>
            <Header />
            <div className={styles.container}>
                <Sidebar cur_page = {cur_page}/>
                <div className={styles.content}>
                    <PageTitle title={title} subtitle={subtitle} buttontxt={buttontxt} handleClick={handleCreateProject}/>
                    <div className={styles.cardContainer}>
                      <ProjectManagementCard type={"Active Projects"} num ={Active_projects.length}/>
                      <ProjectManagementCard type={"Paused Projects"} num ={Paused_projects.length}/>
                      <ProjectManagementCard type={"Completed Projects"} num = {Completed_projects.length}/>
                    </div>
                    <ProjectTableHeader searchText ={searchText} setSearchText ={setSearchText} searchStatus={searchStatus}  setSearchStatus={setSearchStatus} if_search={if_search} setIfSearch={setIfSearch} status={status} setStatus={setStatus} ifShowButton={false} Active_projects={Active_projects} Paused_projects={Paused_projects}  Completed_projects={Completed_projects} setData={setData} fetchProjects={fetchProjects}/>
                    <ProjectTable data={data} reloadProjects={fetchProjects}/>
                </div>
            </div>
        </div>
    );
};

export default ConsolePage;