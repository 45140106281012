import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import config from "../../config/config";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

const StripeForm = ({points}) => {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    const fetchPaymentIntent = async () => {
      try {
        const response = await axios.post(`${config.BACKEND_ENDPOINT}/stripe/create-checkout-session`, {
          amount: points,
        });
        setClientSecret(response.data.clientSecret);
      } catch (error) {
        console.error("Error creating payment intent:", error);
      }
    };

    fetchPaymentIntent();
  }, [points]);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };


  return clientSecret ? (
    <div>
      <Elements options={options} stripe={stripePromise}>
        <CheckoutForm clientSecret={clientSecret} points={points} />
      </Elements>
    </div>
  ) : (
    <div>Loading...</div> // 等待 `clientSecret` 的加载
  );
};

export default StripeForm;
