import React, { useEffect, useState } from 'react';
import { useParams } from'react-router-dom';
import Header from '../../Components/Header/Header';
import Sidebar from "../../Components/SideBar/Sidebar";
import PageTitle from "../../Components/PageTitle/PageTitle";
import ShowProjectDetailForm from '../../Components/ShowProjectDetailForm/ShowProjectDetailForm';
import ProjectDetailSelecter from '../../Components/ProjectDetailSelecter/ProjectDetailSelecter';
import axios from 'axios';
import config from '../../config/config';
import styles from './ProjectDetail.module.css';

const ProjectDetailPage = () => {
  const cur_page = '/console';
  const { id } = useParams();
  const subtitle = 'View your project details';
  const [title, setTitle] =  useState('');
  const [initialValues,setInitialValues] = useState({});
  const [cur_selected, setCurSelected] = useState("jobRequire");
  const fetchProjectData = async () => {
    const response = await axios.post(`${config.BACKEND_ENDPOINT}/project/searchProject`, {
      searchTerm: id
    });
    setTitle(response.data.projects.project_name);
    setInitialValues({
      accumulated_consumption_points:response.data.projects.accumulated_consumption_points,
      project_name: response.data.projects.project_name,
      company_name: response.data.projects.company_name,
      estimated_completion_date: response.data.projects.estimated_completion_date,
      website: response.data.projects.website,
      recruitment_position: response.data.projects.recruitmen_poisition,
      salaryRange_min: response.data.projects.salary_min,
      salaryRange_max: response.data.projects.salary_max,
      job_description: response.data.projects.description,
      job_requirements: response.data.projects.requirements,
      estimated_number_of_recruits: response.data.projects.estimated_num_interviews,
      estimated_number_of_interviews: response.data.projects.estimated_num_recruitments,
      other_requirements: response.data.projects.other_requirements,
      candidate_info_web: response.data.projects.workflow_candidate_website
    })
  }
  useEffect(() => {
    fetchProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  return (
      <div>
        <Header />
        <div className={styles.container}>
            <Sidebar cur_page={cur_page} />
            <div className={styles.content}>
                <PageTitle title={title} subtitle={subtitle} />
                <ProjectDetailSelecter cur_selected={cur_selected} setCurSelected={setCurSelected} id={id}/>
                {(cur_selected === "jobRequire") ?
                <ShowProjectDetailForm values={initialValues} />:
                    <div>
                      candidate List
                    </div>}
            </div>
        </div>
      </div>
  );
};

export default ProjectDetailPage;