import { SIGNIN_SUCCESS, SIGNIN_FAILURE, SIGNOUT, SIGNUP_SUCCESS, SIGNUP_FAILURE, CLEAR_AUTH_ERROR } from "../actions/authActions";

const initialState = {
    user: null,
    error: null,
    isLoggedIn: false,
    encryptedToken: null,
    companyId: null,
    companyName: null
};

export const authReducer = (state = initialState, action) => {
    switch(action.type){
        case SIGNIN_SUCCESS:
            return {
                ...state,
                user: action.payload.userName,
                error: null,
                isLoggedIn: true,
                companyId: action.payload.companyId,
                companyName: action.payload.companyName,
                encryptedToken: action.payload.token,
            }
        case SIGNIN_FAILURE:
            return {
                ...state,
                user: null,
                isLoggedIn: false,
                error: action.error,
            };
        case SIGNOUT:
            return {
                user: null,
                error: null,
                isLoggedIn: false,
                encryptedToken: null,
            }
        case SIGNUP_SUCCESS:
            return {
                ...state,
                user: action.payload.userName,
                error: null,
                isLoggedIn: true,
                companyId: action.payload.companyId,
                companyName: action.payload.companyName,
                encryptedToken: action.payload.token,
            }
        case SIGNUP_FAILURE:
            return {
                ...state,
                user: null,
                isLoggedIn: false,
                error: action.error,
            };
        case CLEAR_AUTH_ERROR:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}