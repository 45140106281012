import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import LoginPage from './Page/LoginPage/LoginPage';
import ConsolePage from './Page/ConsolePage/ConsolePage';
import PointsRechargePage from './Page/PointsRechargePage/PointsRechargePage';
import PaymentSuccessPage from './Page/PaymentSuccessPage/PaymentSuccessPage';
import ProjectDetailPage from './Page/ProjectDetailPage/ProjectDetailPage';


function App() {
  return (
      <Router>
        <div className='App'>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path='/console' element={
              <ProtectedRoute>
                <ConsolePage />
              </ProtectedRoute>
            } />
            <Route path='/project/:id' element={<ProtectedRoute><ProjectDetailPage /></ProtectedRoute>} />
            <Route path='/points' element={<ProtectedRoute><PointsRechargePage /></ProtectedRoute>} />
            <Route path='/payment-success' element={<ProtectedRoute><PaymentSuccessPage /></ProtectedRoute>} />
          </Routes>
        </div>
      </Router>
  );
  }

export default App;