import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    DollarOutlined,
    // TeamOutlined,
    // CalendarOutlined,
    // AuditOutlined,
    FileSearchOutlined
  } from '@ant-design/icons';
import { Menu } from 'antd';
import styles from './Sidebar.module.css';

function Sidebar({cur_page}) {
    const [selectedKey, setSelectedKey] = useState(cur_page)
    const navigate = useNavigate();
    const handleClick = (e) => {
        setSelectedKey(e.key);
        if(e.key !== 'sub1' && e.key !== 'sub2'){
            navigate(e.key);
        }
    };


    // const items1 = [
    //     {
    //       key: 'sub1',
    //       icon: <DollarOutlined />,
    //       label: 'Points and recharge',
    //     },
    //     // {
    //     //   key: 'sub2',
    //     //   icon: <CalendarOutlined />,
    //     //   label: 'Attendance',
    //     // }
    // ];
    
    const items2 = [
        {
            key: '/points',
            icon: <DollarOutlined />,
            label: 'Points and recharge',
        },
        {
          key: '/console',
          icon: <FileSearchOutlined />,
          label: 'Projects',
        },
        // {
        //     key: '/candidates',
        //     icon: <AuditOutlined />,
        //     label: 'Candidates',
        // },
    ];
    return (
        <div className={styles.container}>
            {/* <Menu
                style={{ width: 200 ,borderRight: 'none'}}
                mode="vertical"
                selectedKeys={[selectedKey]}
                onClick={(e)=>{handleClick(e)}}
                items={items1}
            /> */}
            {/* <div className={styles.workbench}>
                Workbench
            </div> */}
            <Menu
                style={{ width: 200,borderRight: 'none', marginTop: '12px'}}
                mode="vertical"
                selectedKeys={[selectedKey]}
                onClick={(e)=>{handleClick(e)}}
                items={items2}
            />
        </div>
    );
}

export default Sidebar;