import React from "react";
import { Radio } from 'antd';
import styles from './ProjectDetailSelecter.module.css'
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const ProjectDetailSelecter = ({cur_selected, setCurSelected,id}) => {
    // const handleEdit = () => {
    //     window.location.href = `/edit/${id}`;
    // }
    return (
        <div className={styles.projectManagement}>
            <RadioGroup value={cur_selected} onChange={(e) => setCurSelected(e.target.value)} size="large" className={styles.textContent}>
                <RadioButton value="jobRequire">Job Requirements</RadioButton>
                {/* <RadioButton value="Candidate">Candidate Details</RadioButton> */}
            </RadioGroup>
            {/* {(cur_selected === "jobRequire") &&<Button type="primary" size="large"  className={styles.createButton} onClick={handleEdit}>Edit</Button>} */}
        </div>
    )
}

export default ProjectDetailSelecter;