import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {signin, clearAuthError} from '../../redux/actions/authActions'
import { useNavigate } from 'react-router-dom';
import PopupModal from '../../Components/PopupModal/PopupModal'
import SignUp from '../../Components/SignUpForm/SignUp';
import './Login.css'; 

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const error = useSelector((state) => state.auth.error);

    const handleLogin = (event) => {
        event.preventDefault();
        dispatch(signin({ userName: username, password: password,type:'client' }));
    };

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/console");
        }
    }, [isLoggedIn, navigate, error]);

    const openModal = () => {
        dispatch(clearAuthError());
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="main-container">
            <div className="left-container">
                <img src="/logo@2x.png" alt="Descriptive Alt Text" className="centered-img" />
                <span className='text_crm'>Aplus-Project Management System</span>
            </div>
            <div className="right-container">
                <div className="container">
                    <div className="content">
                        <div className="text-supporting">
                            <span className="text">Login</span>
                            <span className="supporting-text">Welcome to the CRM System!</span>
                        </div>
                        <form className="content-1" onSubmit={handleLogin}>
                            <div className="form">
                                <div className="input-field">
                                    <div className="input-with-label">
                                        <div className="label-wrapper">
                                            <span className="label">Username</span>
                                        </div>
                                        <div className="input">
                                            <div className='content-3'>
                                                <input
                                                    className="input-2"
                                                    value={username}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    placeholder="Username"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-field-5">
                                    <div className="input-with-label-6">
                                        <div className="label-wrapper-7">
                                            <span className="label-8">Password</span>
                                        </div>
                                        <div className="input-9">
                                            <div className='content-b'>
                                                <input 
                                                    className='input-a'
                                                    type="password" 
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    placeholder="Password"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {error && (
                                        <div className="label-wrapper">
                                            <span className="label-error">{error}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <button className="actions" type="submit">
                                <div className="buttons-button-11">
                                    <div className="text-padding">
                                        <span className="text-12">Log in</span>
                                    </div>
                                </div>
                            </button>
                        </form>
                        <div className="signup-container">
                            <span className="signup-text">Don’t have an account?</span>
                            <span className="signup-link" onClick={openModal}>Sign up</span>
                        </div>
                    </div>
                </div>
            </div>
            <PopupModal isOpen={isModalOpen} onClose={closeModal} >
                <SignUp isOpen={isModalOpen} onClose={closeModal} />
            </PopupModal>
        </div>
    );
}

export default LoginPage;