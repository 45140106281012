import React from "react";
import styles from "./ProjectTable.module.css";
import { Input,Button, Dropdown, Badge,Space } from "antd";
import {DownOutlined } from "@ant-design/icons";
import { useSelector } from 'react-redux';
import axios from 'axios';
import config from '../../config/config';
const { Search } = Input;
const ProjectTableHeader = ({searchText, setSearchText,searchStatus, setSearchStatus,if_search, setIfSearch,status, setStatus,ifShowButton,setData,Active_projects,Paused_projects,Completed_projects,fetchProjects}) => {
    const items = [
        {
        label: 'Active',
        key: '1',
        icon:<Badge color="#34a853" dot style={{marginRight: '3px'}} />
        },
        {
            label: 'Paused',
            key: '2',
            icon: <Badge color="#ea4335" dot style={{marginRight: '3px'}} />
        },
        {
            label: 'Completed',
            key: '3',
            icon: <Badge color="#4285f4" dot style={{marginRight: '3px'}}/>
        }
    ];
    
    const companyName = useSelector((state) => state.auth.companyName);

    const handleStatusChange = (event) => {
        if(event.key === '1'){
            setStatus({ text: 'Active', color: '#34a853' });
            setData(Active_projects);
        }else if(event.key === '2'){
            setStatus({ text: 'Paused', color: '#ea4335' });
            setData(Paused_projects);
        }else if(event.key === '3'){
            setStatus({ text: 'Completed', color: '#4285f4' });
            setData(Completed_projects);
        }
    };

    const menuProps = {
        items,
        onClick: handleStatusChange
    };

    const searchitems = [
        // {
        // label: 'Project ID',
        // key: '1',
        // },
        {
            label: 'Project Name',
            key: '2',
        },
        // {
        //     label: 'Company Name',
        //     key: '3',
        // },
        {
            label: 'Created Date',
            key: '4'
        }
    ];

    const handleSearchStatusChange = (event) => {
        if(event.key === '1'){
            setSearchStatus('Project ID');
        }else if(event.key === '2'){
            setSearchStatus('Project Name');
        }else if(event.key === '3'){
            setSearchStatus('Company Name');
        }else{
            setSearchStatus('Created Date');
        }
    };
    const SearchmenuProps = {
        items: searchitems,
        onClick: handleSearchStatusChange
    };
    const onSearch = async(value) => {
        let searchType ="project_id";

        if(searchStatus === 'Project ID'){
            searchType ="project_id";
        }else if(searchStatus === 'Project Name'){
            searchType ="project_name";
        }else if(searchStatus === 'Company Name'){
            searchType ="company_name";
        }else{
            searchType ="creation_date";
        }
        try {
            const param = {
                searchType: searchType,
                searchTerm: value,
                companyName: companyName
            };
            const response = await axios.post(`${config.BACKEND_ENDPOINT}/project/clientSearchFilter`, param);
            setIfSearch(true);
            setData(response.data.projects);
            setStatus({ text: 'Active', color: '#34a853' });
        } catch (error) {
            console.log(error);
        }
    }

    const handleSearchClear = () => {
        setSearchText('');
        setIfSearch(false);
        setSearchStatus('Project Name');
        setData(Active_projects);
    }

  return (
    <div className={styles.projectListContainer}>
        <div className={styles.projectManagement}>
            <div className={styles.projectListHeader}>
                <h3 className={styles.projectListTitle}>Project list</h3>
                <p className={styles.viewAllLink}>View all Projects Here</p>
            </div>
            {ifShowButton && <button className={styles.createButton}>
                Add Project
            </button>}
        </div>
        <div className={styles.projectListFilters}>
            <div className={styles.searchContainer}>
                <p className={styles.viewAllLink}>Search Project/Client Name/Project Number</p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Dropdown menu={SearchmenuProps}>
                        <Button size="large" style={{ width: '180px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '5px' }}>
                            <DownOutlined style={{ flexShrink: 0 }} />
                            <Space style={{ justifyContent: 'flex-start', flex: 1, marginRight: '18px' }}>
                                <span>
                                    {searchStatus}
                                </span>
                            </Space>
                        </Button>
                    </Dropdown>
                    <Search  
                        placeholder="Search"
                        value={searchText}
                        onChange={(event) => setSearchText(event.target.value)}
                        size="large"
                        onSearch={onSearch} enterButton
                        style={{ width: '500px',display: 'flex', justifyContent: 'space-between',alignItems: 'center',marginTop: '5px',marginLeft: '5px'}}
                    />
                </div>
            </div>

            {if_search ?(
                <div className={styles.searchContaine}>
                    <p className={styles.viewAllLink} style={{ visibility: 'hidden' }}> Clear Button </p>
                    <Button size="large" style={{  width: '150px', 
                        textAlign: 'center',
                        marginTop: '5px', 
                        backgroundColor: '#3271EE', 
                        color: 'white', 
                        border: 'none'}} onClick={handleSearchClear}>
                            Clear
                    </Button>
                </div>
            )
           :(<div className={styles.searchContaine}>
                <p className={styles.viewAllLink}>Status</p>
                <Dropdown menu={menuProps}>
                    <Button size="large" style={{ width: '150px',display: 'flex', justifyContent: 'space-between',alignItems: 'center',marginTop: '5px'}}>
                        <Space>
                                <span>
                                    <Badge color={status.color} dot style={{ marginRight: '10px' }} />
                                    {status.text}
                                </span>
                        </Space>
                        <DownOutlined />
                    </Button>
                </Dropdown>
            </div>)
            }
        </div>
    </div>
  );
};

export default ProjectTableHeader;