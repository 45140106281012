// const backendEndpointProduct = "http://localhost:3001";
// const frontendEndpointProduct = "http://localhost:3000";
const backendEndpointProduct = "https://backend.runaplus.com";
const frontendEndpointProduct = "https://www.runaplus.com";
const stripePublicKey = "pk_test_51PvO092LabM7imvV8vMuS4z2lxmqnJnF7Y8r23E1ADbMsraPSGNw1YmWwhxv4UyXTbu0tdeRq1tjSkb37MpYUXXk00EcOGnjs0";

const config = {
  BACKEND_ENDPOINT: backendEndpointProduct,
  FRONTEND_ENDPOINT: frontendEndpointProduct,
  STRIPE_PUBLIC_KEY: stripePublicKey
};

export default config;