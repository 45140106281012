import React, { useState,useEffect } from "react";
import { CloseOutlined,DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Badge,Space } from "antd";
import styles from "./StatusChange.module.css";
import axios from "axios";
import config from "../../config/config";

const StatusChange = ({ cur_status,project_id,isOpen, onClose,reloadProjects }) => {
    const statusMapping = {
        'Active': { key: '1', text: 'Active', color: '#34a853' },
        'Paused': { key: '2', text: 'Paused', color: '#ea4335' },
        // 'Completed': { key: '3', text: 'Completed', color: '#4285f4' }
    };
    const initialState = statusMapping[cur_status] || statusMapping['Active'];
    const [status, setStatus] = useState(initialState);

    useEffect(() => {
        // 当 cur_status 或 project_id 变化时，更新状态
        setStatus(statusMapping[cur_status] || statusMapping['Active']);
    }, [cur_status, project_id]);

    if(!isOpen) return null;
    const items = [
        {
            label: 'Active',
            key: '1',
            icon: <Badge color="#34a853" dot style={{ marginRight: '3px' }} />
        },
        {
            label: 'Paused',
            key: '2',
            icon: <Badge color="#ea4335" dot style={{ marginRight: '3px' }} />
        },
        // {
        //     label: 'Completed',
        //     key: '3',
        //     icon: <Badge color="#4285f4" dot style={{ marginRight: '3px' }} />
        // }
    ];
    const handleStatusChange = (event) => {
        const newStatus = statusMapping[items.find(item => item.key === event.key).label];
        setStatus(newStatus);
    };
    const menuProps = {
        items,
        onClick: handleStatusChange
    };

    const onConfirm = async() => {
        if(cur_status!== status.text){
            const statInfo = {
                project_id: project_id,
                old_status: cur_status,
                new_status: status.text
            }
            await axios.post(`${config.BACKEND_ENDPOINT}/project/moveProject`,statInfo);
            reloadProjects();
        }
        onClose();
    }

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <button className={styles.closeButton} onClick={onClose}>
                    <CloseOutlined style={{ fontSize: '20px', color: '#98A2B3' }} />
                </button>
                <div className={styles.titleBox}>
                    <div className={styles.title}>Change Status</div>
                    <div className={styles.subtitle}>The project status can be changed.</div>
                </div>

                <div className={styles.searchContainer}>
                    <p className={styles.viewAllLink}>Project Number</p>
                    <Button size="large" style={{ width: '400px',display: 'flex', justifyContent: 'space-between',alignItems: 'center',marginTop: '5px'}}>
                        {project_id}
                    </Button>
                </div>

                <div className={styles.searchContainer}>
                    <p className={styles.viewAllLink}>Status</p>
                    <Dropdown menu={menuProps}>
                        <Button size="large" style={{ width: '400px',display: 'flex', justifyContent: 'space-between',alignItems: 'center',marginTop: '5px'}}>
                        <Space>
                                <span>
                                    <Badge color={status.color} dot style={{ marginRight: '10px' }} />
                                    {status.text}
                                </span>
                        </Space>
                        <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>

                <div className={styles.buttonContainer}>
                    <button className={styles.cancelButton} onClick={onClose}>
                        Cancel
                    </button>
                    <button className={styles.confirmButton} onClick={onConfirm}>
                        Confirm
                    </button>
                </div>

            </div>
        </div>
    );
};

export default StatusChange;