import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Sidebar from "../../Components/SideBar/Sidebar";
import config from "../../config/config";
import axios from "axios";
import { useSelector } from 'react-redux';
import styles from "./PaymentSuccessPage.module.css";

const PaymentSuccessPage = () => {
    const cur_page = '/points';
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [paymentIntentId, setPaymentIntentId] = useState(null);
    const id = useSelector((state) => state.auth.companyId);

    useEffect(() => {
        const fetchPaymentDetails = async () => {
            const queryParams = new URLSearchParams(window.location.search);
            const paymentIntentId = queryParams.get('payment_intent');
            if (paymentIntentId) {
                try {
                    setPaymentIntentId(paymentIntentId);
                    const response = await axios.post(`${config.BACKEND_ENDPOINT}/stripe/payment-intent`, {
                            paymentIntentId: paymentIntentId
                    });
                    setPaymentDetails(response.data);
                    console.log('Payment details:', response.data);
                    const response2 = await axios.post(`${config.BACKEND_ENDPOINT}/invoice/stripe`, {
                        company_id: id,
                        invoice_id: paymentIntentId,
                        amount: response.data.amount /100
                });
                } catch (error) {
                    console.error('Error fetching payment intent:', error);
                }
            }
        };

        fetchPaymentDetails();
    }, [paymentIntentId]);

    return (
        <div className={styles.overall}>
            <Header />
            <div className={styles.container}>
                <Sidebar cur_page={cur_page} />
                
                {paymentDetails && 
                    <div className={styles.Infocontainer}>
                    <img src="/successPayment_copy.png" alt="success" />
                    <h2 className={styles.title}>Recharge successfully</h2>
                    <h1 className={styles.amount}>${paymentDetails.amount / 100}</h1>
                    <p className={styles.message}>Please check on the points page</p>
                    <div className={styles.detailsBox}>
                        <div className={styles.detailItem}>
                            <strong>TO</strong>
                            <p>Aplus</p>
                        </div>
                        <div className={styles.detailItem}>
                            <strong>Recharge time</strong>
                            <p>{new Date(paymentDetails.createTime * 1000).toLocaleString()}</p>
                        </div>
                        <div className={styles.detailItem}>
                            <strong>Payment Method</strong>
                            <p>Card</p>
                        </div>
                        <div className={styles.detailItem}>
                            <strong>Paid With</strong>
                            <p>{paymentDetails.amount / 100} USDT</p>
                        </div>
                    </div>
                    <button 
                        className={styles.backButton}
                        onClick={() => window.location.href = '/points'}
                    >
                        Back
                    </button>
                </div>}

            </div>
        </div>
    )
}

export default PaymentSuccessPage;