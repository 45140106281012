import React,{ useState,useEffect } from "react";
import axios from "axios";
import config from "../../config/config";
import Header from "../../Components/Header/Header";
import Sidebar from "../../Components/SideBar/Sidebar";
import PageTitle from "../../Components/PageTitle/PageTitle";
import PointsInformation from "../../Components/PointsInformation/PointsInformation";
import  Invoicelist from "../../Components/InvoiceList/Invoicelist";
import { useSelector } from 'react-redux';

import styles from "./PointsRechatgePage.module.css";
const PointsRechargePage = () => {
    const cur_page = '/points';
    const title = 'Ponints Recharge';
    const subtitle = 'Here you can manage all your points.';
    const buttontxt = 'Add Candidates';
    const [totalCredits, setTotalCredits] = useState(0);
    const [initialInvoices, setInitialInvoices] = useState([]);
    const id = useSelector((state) => state.auth.companyId);
    
    const fetchCompanyData = async () => {
        try {
          const response = await axios.post(`${config.BACKEND_ENDPOINT}/company/getCompanyById`, {
            company_id: id
          });
          setTotalCredits(response.data.cur_credits + response.data.gift_credits);

          const response2 = await axios.post(`${config.BACKEND_ENDPOINT}/invoice/search`, {
            invoice_id: response.data.most_recent_invoice
          });
          setInitialInvoices(response2.data.info);
          setTotalCredits(response.data.cur_credits + response.data.gift_credits);
        } catch (error) {
          console.error('Error fetching company data:', error);
        }
      };
    
      useEffect(() => {
        fetchCompanyData();
      }, []);

    return (
        <div className={styles.overall}>
        <Header />
            <div className={styles.container}>
                <Sidebar cur_page = {cur_page}/>
                <div className={styles.content}>
                    <PageTitle title={title} subtitle={subtitle} buttontxt={buttontxt}/>
                    <PointsInformation points={totalCredits}/>

                    <div className={styles.projectManagement}>
                        <div className={styles.projectListHeader}>
                            <h3 className={styles.projectListTitle}>Invoice Records</h3>
                            <p className={styles.viewAllLink}>You can view transaction points records here. </p>
                        </div>
                    </div>
                    <Invoicelist invoices={initialInvoices} />
                </div>
            </div>
        </div>
    );
};

export default PointsRechargePage;