import React, { useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import config from "../../config/config";
import styles from "./CheckoutForm.module.css";

const CheckoutForm = ({ clientSecret, points }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    try {
      // 调用 elements.submit() 以便 Stripe 处理表单中的内容
      const { error: submitError } = await elements.submit();
      if (submitError) {
        // 如果表单提交有错误，显示错误信息
        setMessage(submitError.message);
        setLoading(false);
        return;
      }

      // 表单提交成功，调用 confirmPayment 进行支付
      const { error: paymentError, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${config.FRONTEND_ENDPOINT}/payment-success`,
        },
        clientSecret,
      });

      if (paymentError) {
        if (paymentError.type === "card_error" || paymentError.type === "validation_error") {
          setMessage(paymentError.message);
        } else {
          setMessage("An unexpected error occurred.");
        }
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        setMessage(`Payment successful for ${points} points`);
      }
    } catch (error) {
      setMessage("An unexpected error occurred.");
    }

    setLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <div className={styles.buttonContainer}>
          <button disabled={loading || !stripe || !elements} className={styles.submitButton} id="submit">
            <span id="button-text">
              {loading ? <div className="spinner" id="spinner"></div> : "Pay now"}
            </span>
          </button>
        </div>
        {message && <div id="payment-message">{message}</div>}
      </form>
    </>
  );
};

export default CheckoutForm;