import config from "../../config/config";
import axios from 'axios';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE';
export const SIGNOUT = 'SIGNOUT';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';

export const signin =(userData) =>{
    return async dispatch => {
        try {
          const response = await axios.post(`${config.BACKEND_ENDPOINT}/auth/login`, userData);

          if (response.status === 200) {
            dispatch({
              type: SIGNIN_SUCCESS,
              payload: response.data,
            });
          } else {
            dispatch({
              type: SIGNIN_FAILURE,
              error: response.data
            });
          }
        } catch (error) {
          console.log(error)
          dispatch({
            type: SIGNIN_FAILURE,
            error: error.response.data
          });
        }
      }
}

export const signout = () =>{
  return async dispatch =>{
      dispatch(
          {type:SIGNOUT}
      )
  }
}

export const signup =(userData) =>{
  return async dispatch => {
      try {
        const response = await axios.post(`${config.BACKEND_ENDPOINT}/auth/signup`, userData);
        console.log(response);
        if (response.status === 200) {
          dispatch({
            type: SIGNUP_SUCCESS,
            payload: response.data,
          });
        } else {
          dispatch({
            type: SIGNUP_FAILURE,
            error: response.data
          });
        }
      } catch (error) {
        console.log(error)
        dispatch({
          type: SIGNUP_FAILURE,
          error: error.response.data
        });
      }
    }
}

export const clearAuthError = () => ({
  type: CLEAR_AUTH_ERROR,
});

