import React from 'react';
import { signout } from "../../redux/actions/authActions";
import { useDispatch,useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import styles from './Header.module.css';

import { Button, Dropdown, Space } from 'antd';
import { DownOutlined, UserOutlined,LogoutOutlined } from '@ant-design/icons';

function Header() {
    const user = useSelector((state) => state.auth.user) || 'Staff Member 1';
    const items = [
        {
          label: 'logout',
          key: '1',
          icon:<LogoutOutlined />
        }
    ];

    const handleMenuClick = (e) => {
        if(e.key === '1'){
            handleLogOut();
        }
      };

    const menuProps = {
        items,
        onClick: handleMenuClick
    };

    const dispatch = useDispatch();
    const handleLogOut = () => {
        dispatch(signout());
        window.location.href = '/';
    }

    return (
        <header className={styles.header}>
            <div className={styles.logoBox}>
                <Link to="/console">
                    <img src="/Secondary_1.png" alt="2Usmiles Logo" className={styles.centeredImg} />
                </Link>
            </div>

            <div className={styles.rightContent}>
                <Dropdown menu={menuProps}>
                    <Button style={{ fontSize: '14px', padding: '2px 4px', width: '177px',height: 'auto' }}>
                        <Space>
                        <UserOutlined style={{fontSize: '23px'}}/>
                        <div className={styles.titleContainer}>
                            <div className={styles.mainTitle}>Aplus</div>
                            <div className={styles.subTitle}>{user}</div>
                        </div>
                        <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            </div>
        </header>
    );
}

export default Header;