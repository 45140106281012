import React from 'react';
import styles from './PageTitle.module.css';

function PageTitle({title,subtitle,buttontxt,handleClick}) {
    return (
        <div className={styles.projectManagement}>
            <div className={styles.textContent}>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.subtitle}>
                        {subtitle}
                </p>
            </div>
            {/* {buttontxt &&<button className={styles.createButton} onClick={handleClick}>
                {buttontxt}
            </button>} */}
        </div>
    );
}

export default PageTitle;