import React from "react";
import { List } from 'antd';

function Invoicelist({ invoices }) {
  return (
    <List
      itemLayout="vertical"
      size="large"
      pagination={{
        onChange: page => {
        //   console.log(page);
        },
        pageSize: 5,
      }}
      dataSource={invoices}
      renderItem={item => (
        <List.Item
          key={item.id}
          style={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #e0e0e0',
            padding: '16px',
            borderRadius: '8px',
            marginLeft: '26px',
            marginBottom: '10px',
          }}
        >
          <div style={{ flex: 1, marginLeft: '10px' }}>
            <List.Item.Meta
              title={item.project_name}
              description={item.description}
            />
            {item.created_at}
          </div>

          <div
            style={{
              width: '40px', // 设置固定宽度
              minWidth: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src="/coins-stacked-01.svg"
              alt="Coins Icon"
              style={{
                width: '30px',
                height: '30px',
                marginRight: '25px',
              }}
            />
          </div>

          <div
            style={{
              minWidth: '70px', // 可根据需要调整
              textAlign: 'right',
              fontSize: '32px',
              color: item.type === 'add' ? 'green' : 'red',
            }}
          >
            {item.type === 'add' ? `+${item.amount}` : `-${item.amount}`}
          </div>
        </List.Item>
      )}
    />
  );
}

export default Invoicelist;