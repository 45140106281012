import React, { useState } from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import StatusChange from '../StatusChange/StatusChange';
import styles from './Table.module.css';

const ProjectTable = ({data,reloadProjects}) => {
    const [, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const handleChange = (pagination, filters, sorter) => {
      console.log('Various parameters', pagination, filters, sorter);
      setFilteredInfo(filters);
      setSortedInfo(sorter);
    };

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [selectedProjectStatus, setselectedProjectStatus] = useState(null);
    const showModal = (projectId,status) => {
      setSelectedProjectId(projectId);
      setselectedProjectStatus(status);
      setIsModalVisible(true);
    };

    const handleClose = () => {
      setIsModalVisible(false);
    };

    const columns = [
        {
            title: 'Project ID',
            dataIndex: 'project_id',
            key: 'project_id',
            width: 150,
            render: (text, record) => (
              <Link to={`/project/${record.project_id}`}>
                  {record.project_id}
              </Link>
          ),
        },
        {
          title: 'Project Name',
          dataIndex: 'project_name',
          key: 'project_name',
          width: 150,
        },
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            key: 'company_name',
            width: 150,
          },
        {
          title: 'Creation Date',
          dataIndex: 'creation_date',
          key: 'creation_date',
          width: 150,
          sorter: (a, b) => new Date(a.creation_date) - new Date(b.creation_date),
          sortOrder: sortedInfo.columnKey === 'creation_date' ? sortedInfo.order : null,
          ellipsis: true,
        },
        {
          title: 'Email Responses',
          dataIndex: 'email_reponese',
          key: 'email_reponese',
          width: 150,
          sorter: (a, b) => a.email_reponese - b.email_reponese,
          sortOrder: sortedInfo.columnKey === 'email_reponese' ? sortedInfo.order : null,
          ellipsis: true,
        },
        {
          title: 'Interview',
          dataIndex: 'interview',
          key: 'interview',
          width: 150,
          sorter: (a, b) => a.interview - b.interview,
          sortOrder: sortedInfo.columnKey === 'interview' ? sortedInfo.order : null,
          ellipsis: true,
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (text, record) => {
            
            let cur_style = null;
            let text_style = null;
            if (record.status === 'Active') {
              cur_style = styles.badgeActive;
              text_style = styles.badgeActiveTxt;
            } else if (record.status === 'Completed') {
              cur_style = styles.badgeCompleted;
              text_style = styles.badgeCompletedTxt;
            } else if (record.status === 'Paused') {
              cur_style = styles.badgePaused;
              text_style = styles.badgePausedTxt;
            }
            const handleShowModal = () => {
              showModal(record.project_id,record.status);
            };
            return (
              <div>
                <div className={cur_style} onClick={handleShowModal}>
                  <div className={text_style}>
                    {record.status}
                  </div>
                </div>
                <StatusChange project_id={selectedProjectId} cur_status={selectedProjectStatus} isOpen={isModalVisible} onClose={handleClose} reloadProjects={reloadProjects}/>
              </div>
            );
          },
          width: 150,
        },
      ];
  return <Table columns={columns} dataSource={data} onChange={handleChange} rowKey="project_id"  pagination={{ pageSize: 5 }}
   style={{
    marginLeft: '30px',
    marginRight:'10px',
    border: '1px solid #E4E7EC',
    backgroundColor: '#fff',
    borderRadius: '8px',
}} />;
};

export default ProjectTable;