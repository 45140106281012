import React from'react';
import styles from './ProjectManagementCard.module.css';
const ProjectManagementCard = ({type,num}) => {
  let img = "/fileplus02.svg";
  if (type === 'Active Projects') {
  }else if (type === 'Paused Projects') {
    img = "/fileminus02.svg";
  }else if(type === 'Completed Projects'){
    img = "/filecheck02.svg";
  }
  return (
    <div className={styles.projectManagementCard}>
      <div className={styles.iconContainer}>
        <img src={img} alt="file Logo" className={styles.centeredImg} />
      </div>
      <div className={styles.headerandnumber}>
        <h1 className={styles.title}>{type}</h1>
        <div className={styles.numberContainer}>
          <div className={styles.number}>
            {num}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectManagementCard;